if (pageDetails.fileName === 'heating.html' || pageDetails.fileName === 'heating') {
    window.addEventListener('load', () => {


        /* ---------------------------------------------- Service Locations Section --------------------------- */

        // Grab all elements that we need to interact with within the service lcoations section.
        const countySelectContainer = document.getElementById('county-select');
        const countySelectImage = countySelectContainer.querySelector('img');
        const countyButtons = Array.from(countySelectContainer.getElementsByTagName('button'));

        const dividerLines = Array.from(countySelectContainer.getElementsByTagName('hr'));

        // The list of cities that appears with each county
        const cityLists = countyButtons.map(button => button.nextElementSibling);

        // The grey text above the county select container
        const countyPrompt = document.getElementById('county-select-prompt');

        // The image used as the background for the container
        let initialImage = countySelectImage.getAttribute('src');
        let lastButton;
        let lastCityList;

        // This bit is dependent on the text inside the button elements, it will take the
        // inner text and transform it into a Google Static Maps API request for that location.
        // This fetched Google Maps static image will then be used as the background
        countyButtons.forEach((button, index) => {
            let location = encodeURI(button.innerText);
            let mapsRequestURL =
                'https://maps.googleapis.com/maps/api/staticmap' +
                `?center=%7C${location}` +
                '&scale=2' +
                '&size=480x240' +
                '&zoom=9' +
                '&key=AIzaSyBuVI4a_0umKsQv34Q8e9ruN0d8fklIFxs';

            let list = cityLists[index];

            // Store the amount of Y-cor translation to do to make this button appear at the top. 
            // Offset by 5px to account for the container border that is factored in
            let translateDistance = countySelectContainer.getBoundingClientRect().top 
                - button.getBoundingClientRect().top + 5;

            window.addEventListener('resize', () => {
                // Recalculate the translate distance while resizing
                translateDistance = countySelectContainer.getBoundingClientRect().top 
                - button.getBoundingClientRect().top + 5;
            });

            button.addEventListener('click', () => {
                if(!lastButton) {
                    /* No button is currently toggled */

                    // Hide all dividerlines and other buttons
                    dividerLines.forEach(line => line.style.opacity = 0);
                    countyButtons.forEach((otherButton) => {
                        if (otherButton !== button) {
                            otherButton.style.opacity = 0;
                            otherButton.disabled = true;
                            otherButton.style.pointerEvents = 'none';
                        }
                        countyPrompt.innerText = 'Click on the name again to return to options.';
                    });

                    // Set the Google Maps location image as the background
                    countySelectImage.setAttribute('src', mapsRequestURL);

                    // Make this county button appear at the top of the container
                    button.style.transform = `translateY(${translateDistance}px)`;

                    // Show the list of cities in this county
                    list.style.opacity = 1;

                    // Set the current button and list as currently toggled
                    lastButton = button;
                    lastCityList = list;

                } else if (lastButton !== button && lastCityList !== list) {
                    /* Selected a different button than what is currently toggled */

                    // Hide the last list of cities
                    lastCityList.style.opacity = 0;

                    // Hide the last button and reset its position to its original spot
                    lastButton.style.opacity = 0;
                    lastButton.style.transform = `translateY(0px)`;

                    // Set the background image to the new google map location
                    countySelectImage.setAttribute('src', mapsRequestURL);

                    // Show this button and make it appear at the top
                    button.style.opacity = 1;
                    button.style.transform = `translateY(${translateDistance}px)`;

                    // Set the current button and list as currently toggled (last)
                    lastButton = button;
                    lastCityList = list;


                } else {
                    /* Selected the same button that is currently toggled */

                    // Hide the list of cities
                    list.style.opacity = 0;

                    // Reset the background image
                    countySelectImage.setAttribute('src', initialImage);

                    // Reset this buttons position to its original spot
                    button.style.transform = `translateY(0px)`;

                    // Make all other buttons and divider lines reappear
                    dividerLines.forEach(line => line.style.opacity = 1);
                    countyButtons.forEach((otherButton) => {
                        if (otherButton !== button) {
                            otherButton.style.opacity = 1;
                            otherButton.disabled = false;
                            otherButton.style.pointerEvents = '';
                        }
                        countyPrompt.innerText = 'Click on a county to view a list of cities.';
                    });

                    // Set the last button to null (nothing is currently toggled)
                    lastButton = null;
                    lastCityList = null;

                }
            });
        });

    });
}