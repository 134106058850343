if (pageDetails.fileName === 'heating.html' || pageDetails.fileName === 'heating' ||
pageDetails.fileName === 'air-conditioning.html' || pageDetails.fileName === 'air-conditioning' ||
pageDetails.fileName === 'water-heating.html' || pageDetails.fileName === 'heating' || 
pageDetails.fileName === 'hvac-installations.html' || pageDetails.fileName === 'hvac-installations') {

    window.addEventListener('load', () => {

        // The bottom overlay appears when you scroll down far enough on either the services page or the crew page.
        // Its purpose is to ease navigation within the pages themselves since they offer a rather large scroll,
        // especially on small screen sizes.

        // NOTE: The click handlers for these buttons are applied within the services-script and crew-script individually.

        const bottomOverlay = document.getElementById('bottom-overlay');

        // Grab all anchor points on the page (the sections on the services page and the sections on the crew page)
        const anchorPoints = Array.from(document.querySelectorAll('.anchor-contact,.anchor'));
        console.log(anchorPoints);

        // Specify when the bottom overlay should appear (or disappear) as the toggle point
        let togglePoint;
        function setTogglePoint() {
            if (pageDetails.fileName === 'heating.html') {
                // If we're on the services page, the bottom overlay should appear right at the second seperator: anchorPoints[1]
                // only if the screen is fairly wide and therefore we assume that it isn't much of a scroll down.
                // Otherwise let it appear after the first seperator: anchorPoints[0].
                togglePoint = anchorPoints[window.innerWidth >= 745 ? 1 : 0].getBoundingClientRect().top - 70;
            } else {
                // If we're on the crew page just let it appear after you scroll past the top of the first section element.
                togglePoint = anchorPoints[0].getBoundingClientRect().top - 70;
            }
        }

        // Immediately call the function so we can set the toggle point when the page loads
        setTogglePoint(); 
        // Check if we are already below the toggle point when we load the page and toggle accordingly
        let overlayToggled = window.pageYOffset >= togglePoint + window.pageYOffset;
        if (overlayToggled) {
            // Toggle it on page load if past the scroll point
            bottomOverlay.style.display = 'flex';
        }

        window.addEventListener('scroll', () => {
            // The bounding client rectangle is different everytime we scroll so we need to keep reseting the toggle point
            setTogglePoint();

            if (!overlayToggled && window.pageYOffset >= togglePoint + window.pageYOffset) {
                overlayToggled = true;
                bottomOverlay.style.display = 'flex';
                console.log(true);

            } else if (overlayToggled && window.pageYOffset < togglePoint + window.pageYOffset) {
                overlayToggled = false;
                bottomOverlay.style.display = 'none';
                console.log(false);

            }
        });

        // Also reset toggle point when we resize just in case
        window.addEventListener('resize', setTogglePoint);
    });
}