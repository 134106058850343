if (pageDetails.fileName === 'hvac.html' || pageDetails.fileName === 'hvac' || 
pageDetails.fileName === 'heating.html' || pageDetails.fileName === 'heating' ||
pageDetails.fileName === 'air-conditioning.html' || pageDetails.fileName === 'air-conditioning' || 
pageDetails.fileName === 'water-heating.html' || pageDetails.fileName === 'water-heating' || 
pageDetails.fileName === 'hvac-installations.html' || pageDetails.fileName === 'hvac-installations' || 
pageDetails.fileName === 'FAQ.html' || pageDetails.fileName === 'FAQ') {
    window.addEventListener('load', () => {
        
        const sideNavselector = document.getElementsByClassName('sidenav-selector');
        const sideNavmenu = document.getElementsByClassName('sidenav-menu');


        // ----------------------------- Initial Animation ---------------------

        let initialized = false;

        anime.timeline({
            easing: 'easeInOutSine',
        }).add({
            targets: sideNavselector,
            opacity: [0, 1],
            duration: 600,
            translateX: [-80, 0],
        }).add({
            targets: sideNavmenu,
            opacity: [0, 1],
            duration: 300,
            translateX: [-80, 0],
            complete: () => {
                initialized = true;
            }

        });
        
    });
    
}

function openNav() {
    document.getElementById("mySidenav").style.width = "175px";
}

function closeNav() {
    document.getElementById("mySidenav").style.width = "0px";
}

