if (pageDetails.fileName === 'generators.html' || pageDetails.fileName === 'generators' || 
pageDetails.fileName === 'generator-sales.html' || pageDetails.fileName === 'generator-sales' ||
pageDetails.fileName === 'generator-installations.html' || pageDetails.fileName === 'generator-installations' ||
pageDetails.fileName === 'generator-monitoring.html' ||  pageDetails.fileName === 'generator-monitoring' || 
pageDetails.fileName === 'generator-preventative-maintenance.html' || pageDetails.fileName === 'generator-preventative-maintenance') {
    window.addEventListener('load', () => {
        // ----------------------- Cache All Interactive Elements -----------------------
        const mainHeader = document.getElementById('service-button-header-wrapper').firstElementChild;

        const serviceCategories = Array.from(document.getElementsByClassName('serviceCategory'));

        const categoryHeaderWrappers = serviceCategories.map(category => category.firstElementChild);
        const categoryHeaders = categoryHeaderWrappers.map(wrapper => wrapper.firstElementChild);

        const serviceLists = serviceCategories.map(category => category.querySelector('.serviceList'));
        const serviceListItems = serviceLists.map(list => Array.from(list.getElementsByTagName('li')));

        const serviceInfoContainers = serviceLists.map(list => list.nextElementSibling);
        const serviceBlurbs = serviceInfoContainers.map(container => Array.from(container.getElementsByTagName('li')));
        const blurbBackButtons = serviceInfoContainers.map(container => Array.from(container.getElementsByClassName('scrollUp')));

        // ----------------------- Initial Animation -----------------------
        let initialized = false;

        anime.timeline({
            easing: 'easeInOutSine',
        }).add({
            targets: mainHeader,
            opacity: [0, 1],
            duration: 600,
        }).add({
            targets: categoryHeaderWrappers,
            opacity: [0, 1],
            translateX: [-80, 0],
            duration: 300,
            delay: anime.stagger(150),
            complete: () => {
                initialized = true;
                /**
                    *  Generator Sales          -> categoryHeaderWrappers[0]
                    *  Installations            -> categoryHeaderWrappers[1]
                    *  Monitoring               -> categoryHeaderWrappers[2]
                    *  Preventative Maintenance -> categoryHeaderWrappers[3]
                    */
                    
            }
        });


        // ----------------------- Attach Event Listeners -----------------------
        let animatedCategory,
            selectedCategory,
            revealedList,
            selectedItem,
            revealedBlurb;

        function revealList(wrapper, index) {
            if (initialized && !animatedCategory) {

                if (!selectedCategory) {
                    animatedCategory = wrapper;
                    anime.timeline({
                        complete: () => {
                            animatedCategory = null;
                            selectedCategory = wrapper;
                            revealedList = serviceLists[index];
                            listInnerBottomMargin = revealedList.getBoundingClientRect().bottom - revealedList.lastElementChild.getBoundingClientRect().bottom;
                            scrollToElement(selectedCategory);

                        }
                    })
                        .add(selectServiceCategory(wrapper, categoryHeaders[index]))
                        .add(reveal(serviceLists[index]));

                } else if (selectedCategory !== wrapper) {
                    animatedCategory = wrapper;
                    let timeline = anime.timeline({
                        complete: () => {
                            animatedCategory = null;
                            selectedCategory = wrapper;
                            revealedList = serviceLists[index];
                            listInnerBottomMargin = revealedList.getBoundingClientRect().bottom - revealedList.lastElementChild.getBoundingClientRect().bottom;
                            scrollToElement(selectedCategory);

                            if (selectedItem && revealedBlurb) {
                                selectedItem = null;
                                revealedBlurb = null;
                            }
                        }
                    });

                    timeline
                        .add(selectServiceCategory(wrapper, categoryHeaders[index]))
                        .add(reveal(serviceLists[index]));

                    if (selectedItem && revealedBlurb) {
                        timeline
                            .add(deselectItem(selectedItem))
                            .add(hide(revealedBlurb), '-=200');
                    }

                    timeline
                        .add(hide(revealedList))
                        .add(deselectServiceCategory(selectedCategory), '-=200');


                } else {
                    animatedCategory = wrapper;
                    let timeline = anime.timeline({
                        complete: () => {
                            animatedCategory = null;
                            selectedCategory = null;
                            revealedList = null;

                            if (selectedItem && revealedBlurb) {
                                selectedItem = null;
                                revealedBlurb = null;
                            }
                        }
                    });

                    if (selectedItem && revealedBlurb) {
                        timeline
                            .add(deselectItem(selectedItem))
                            .add(hide(revealedBlurb), '-=200');
                    }

                    timeline
                        .add(hide(serviceLists[index]))
                        .add(deselectServiceCategory(wrapper, categoryHeaders[index]));

                }
            }
        } // End revealList()

       

        // This is where the dream works
       
        categoryHeaderWrappers.forEach((wrapper, index) => {

            // ----------------------- Main Category Wrappers -----------------------
            wrapper.addEventListener('mouseenter', () => {
                if (initialized &&
                    selectedCategory !== wrapper &&
                    animatedCategory !== wrapper) {

                    anime({
                        targets: wrapper,
                        backgroundColor: 'rgb(226, 18, 20, .4)',
                        color: '#ffffff',
                        duration: 300,
                        easing: 'linear'
                    });

                }
            }); // End mouseenter event

            wrapper.addEventListener('mouseleave', () => {
                if (initialized &&
                    selectedCategory !== wrapper &&
                    animatedCategory !== wrapper) {

                    anime({
                        targets: wrapper,
                        backgroundColor: '#ffffff',
                        color: 'rgb(46, 37, 37)',
                        duration: 300,
                        easing: 'linear'
                    });

                }
            }); // End mouseleave event

           // CLICK HANDLER
           // main category wrapper click event
            wrapper.addEventListener('click', () => {
                revealList(wrapper, index);
            })

            

        


            // ----------------------- Subcategory (Service List Items) -----------------------
            serviceListItems[index].forEach((item, j) => {

                item.addEventListener('click', () => {

                    if (!selectedItem && !revealedBlurb) {

                        // ----- Service Button -----
                        anime(selectItem(item))
                            .finished.then(() => {
                                selectedItem = item;
                            });

                        // ----- Blurb -----
                        anime(reveal(serviceBlurbs[index][j]))
                            .finished.then(() => {
                                revealedBlurb = serviceBlurbs[index][j];
                                if (window.innerHeight <= 820) scrollToElement(revealedBlurb);

                            });


                    } else if (selectedItem !== item &&
                        revealedBlurb !== serviceBlurbs[index][j]) {

                        // ----- Service Button -----
                        anime.timeline({
                            complete: () => {
                                selectedItem = item;
                            }
                        })
                            .add(deselectItem(selectedItem))
                            .add(selectItem(item));

                        // ----- Blurb -----
                        anime.timeline({
                            complete: () => {
                                revealedBlurb = serviceBlurbs[index][j];
                                if (window.innerHeight <= 820) scrollToElement(revealedBlurb);

                            }
                        })
                            .add(hide(revealedBlurb))
                            .add(reveal(serviceBlurbs[index][j]));


                    } else {
                        // ----- Service Button -----
                        anime(deselectItem(item))
                            .finished.then(() => {
                                selectedItem = null;
                            });

                        // ----- Blurb -----
                        anime(hide(serviceBlurbs[index][j]))
                            .finished.then(() => {
                                revealedBlurb = null;
                                if (window.innerHeight <= 820) scrollToElement(categoryHeaderWrappers[index]);
                            });

                    }

                }); // End click event

            }); // End serviceListItems[index].forEach()


            // ----------------------- Blurb Back Buttons -----------------------
            blurbBackButtons[index].forEach((button) => {
                button.addEventListener('click', () => {
                    scrollToElement(categoryHeaderWrappers[index]);
                });
            });


        }); // End the main event handler loop - categoryHeaderWrappers.forEach() 



        // -----------------------  Utilitiy Functions -----------------------

        /**
         * Scroll to the top of the given element, minus some pixels for the navbar and margins
         * @param { Object } element HTMLDOMElement to scroll to the top most pixel of
         * @param { boolean } auto Whether to use auto (instantaneous) scrolling, excluding this defaults to smooth scrolling
         */
        const scrollToElement = function (element, auto) {
            window.scroll({
                top: element.getBoundingClientRect().top + window.pageYOffset - 45,
                behavior: !auto ? 'smooth' : 'auto'
            });
        }



        // -----------------------  Animation Wrappers -----------------------
        const selectServiceCategory = function (wrapper, innerHeader) {
            // anime.remove(wrapper);
            return {
                targets: wrapper,
                height: {
                    value: innerHeader.scrollHeight,
                    easing: 'easeInOutSine'
                },
                backgroundColor: {
                    value: 'rgb(226, 18, 20, 0.4)',
                    easing: 'linear'
                },
                color: '#2E2525',
                duration: 200
            };
        } // End selectServiceCategory()

        const deselectServiceCategory = function (wrapper) {
            // anime.remove(wrapper);
            return {
                targets: wrapper,
                height: {
                    value: '150px',
                    easing: 'easeInOutSine'
                },
                backgroundColor: {
                    value: '#FFFFFF',
                    easing: 'linear'
                },
                duration: 200
            };

        } // End deselectServiceCategory()

        const reveal = function (element) {
            anime.remove(element);
            return {
                targets: element,
                height: element.scrollHeight + 10,
                opacity: [0, 1],
                easing: 'easeInOutSine',
                duration: 200
            };
        } // End reveal()

        const hide = function (element) {
            anime.remove(element);
            return {
                targets: element,
                height: '0px',
                opacity: [1, 0],
                easing: 'easeInOutSine',
                duration: 200
            };
        } // End hide()

        const selectItem = function (item) {
            anime.remove(item);
            return {
                targets: item,
                color: '#FFFFFF',
                backgroundColor: '#686161',
                duration: 200,
                easing: 'linear'
            };
        } // End selectItem()

        const deselectItem = function (item) {
            anime.remove(item);
            return {
                targets: item,
                color: '#E21214',
                backgroundColor: 'rgba(104, 97, 97, 0.1)',
                duration: 200,
                easing: 'linear'
            };
        } // End hideItem()


        // ----------------------- Window Resize Event Handlers -----------------------

        // Adjust the height of the service category child elements on resize
        let listInnerBottomMargin; // Set on a reveal(list) animation completion
        window.addEventListener('resize', () => {

            if (revealedList) {
                if (selectedCategory.style.height !== selectedCategory.scrollHeight) {
                    selectedCategory.style.height = selectedCategory.firstElementChild.scrollHeight + 'px';
                }

                let newListInnerBottomMargin = revealedList.getBoundingClientRect().bottom - revealedList.lastElementChild.getBoundingClientRect().bottom;
                if (newListInnerBottomMargin !== listInnerBottomMargin) {
                    revealedList.style.height = (revealedList.scrollHeight + listInnerBottomMargin - newListInnerBottomMargin) + 'px';
                }

                if (revealedBlurb &&
                    revealedBlurb.style.height !== revealedBlurb.firstElementChild.scrollHeight + 10) {
                    revealedBlurb.style.height = revealedBlurb.firstElementChild.scrollHeight + 10 + 'px';
                }
            }
        });

    });
}


