const pageDetails = Object.freeze({
    fileName: location.pathname.split('/').pop()
});

// -----------------------  Utilitiy Functions -----------------------

/**
 * Scroll to the top of the given element, minus some pixels for the navbar and margins
 * @param { Object } element to scroll to the top most pixel of
 * @param { number } amount of pixels to offset the final scroll point (such as for headers or margin)
 * @param { boolean } auto Whether to use auto (instantaneous) scrolling, excluding this defaults to smooth scrolling
 */
const scrollToElement = function(element, offset, auto) {
    window.scroll({
        top: element.getBoundingClientRect().top + window.pageYOffset - offset,
        behavior: !auto ? 'smooth' : 'auto'
    });
}